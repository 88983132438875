
import {
  Component, Prop, Vue, Watch,
} from 'nuxt-property-decorator'
import type { FSXARichText } from 'fsxa-pattern-library'
import { RichTextElement } from 'fsxa-api'
import IImage from '../../shared/general/interfaces/IImage'
import { ILink } from '../../shared/general/interfaces/ILink'
import IVideo from '../../shared/general/interfaces/IVideo'
import { openLightbox } from '../../shared/general/services/LightboxService'
import CarouselImage from './CarouselImage.vue'
import CarouselSlideContent from './CarouselSlideContent.vue'
import IImageVideoContent from '../../shared/general/interfaces/IImageVideoContent'

@Component({
  name: 'CarouselElement',
  components: {
    CarouselImage,
    CarouselSlideContent,
    FSXARichText: async () => (await import('fsxa-pattern-library')).FSXARichText,
  },
})
export default class CarouselElement extends Vue {
  @Prop({ default: () => [] }) links! : ILink[]

  @Prop({ default: () => [] }) headlines! : FSXARichText[]

  @Prop({ default: '' }) headline! : string

  @Prop({ default: '' }) text! : string

  @Prop({ required: true }) image! : IImage

  @Prop({ default: true }) lazy! : boolean

  @Prop({ required: true }) variant! : 'stage-large' | 'stage-medium' | 'carousel'

  @Prop({ required: true }) controlsGap! : boolean

  @Prop() checkmarks ?: RichTextElement[][]

  @Prop() video ?: IVideo

  @Prop({ default: '' }) wrapperSpacings! : string

  private showCookieLayer : boolean = false

  private headlineSizes : Record<string, string> = {
    'stage-large': 'text-3xl sm:text-5xl lg:text-6xl',
    'stage-medium': 'text-3xl sm:text-4xl xl:text-5xl',
    carousel: 'text-xl sm:text-2xl',
  }

  private wrapperClasses : Record<string, string> = {
    'stage-large': 'mx-auto flex md:items-end flex-col md:flex-row max-h-[40rem] min-h-[62vh]',
    'stage-medium': 'mx-auto flex md:items-end flex-col md:flex-row min-h-[400px]',
    carousel: 'h-full flex md:items-end flex-col md:flex-row min-h-[240px] sm:min-h-[300px]',
  }

  mounted () {
    this.showCookieLayer = this.checkShowCookieLayer()
  }

  private checkShowCookieLayer () : boolean {
    if (!process.client) return true

    if (!this.video?.id) return false
    if (!this.video?.format) return false
    if (this.video.format === 'html5') return false

    switch (this.video.format) {
      case 'youtube':
        return !this.youtubeCookiesAccepted
      case 'vimeo':
        return !this.vimeoCookiesAccepted
      case 'tencent':
        return !this.tencentCookiesAccepted
      default:
        return false
    }
  }

  private showLightbox () : void {
    const content : IImageVideoContent = (this.video?.id && !['html5'].includes(this.video?.format || '')) ? {
      tag: 'BaseVideo',
      props: {
        id: this.video.id,
        format: this.video.format,
        privacyHash: this.video.privacyHash,
      },
    } : {
      tag: 'BaseHtmlPlayer',
      props: {
        mediaType: 'video',
        image: this.image,
        htmlPlayerElements: this.video?.htmlPlayerElements,
      },
    }
    openLightbox(content)
  }

  private get youtubeCookiesAccepted () : boolean {
    return !!this.$store.state.PrivacySettings.settings.youtube
  }

  private get vimeoCookiesAccepted () : boolean {
    return !!this.$store.state.PrivacySettings.settings.vimeo
  }

  private get tencentCookiesAccepted () : boolean {
    return !!this.$store.state.PrivacySettings.settings.tencent
  }

  @Watch('$store.state.PrivacySettings.settings.youtube')
  @Watch('$store.state.PrivacySettings.settings.vimeo')
  @Watch('$store.state.PrivacySettings.settings.tencent')
  private onVideoProviderConsentChange () {
    this.showCookieLayer = this.checkShowCookieLayer()
  }
}
