
import {
  Component, Emit, Prop, Vue,
} from 'nuxt-property-decorator'
import { RichTextElement } from 'fsxa-api'
import { ILink } from '../../shared/general/interfaces/ILink'
import IImage from '../../shared/general/interfaces/IImage'
import IVideo from '../../shared/general/interfaces/IVideo'
import BaseLink from '../base/BaseLink.vue'
import EButtonVariant from '../../shared/general/enums/EButtonVariant'
import { globalLabelAsString } from '../../shared/general/services/StoreService'
import Checkmark from '../svgs/Checkmark.vue'

@Component({
  name: 'CarouselSlideContent',
  components: {
    BaseLink,
    Checkmark,
    FSXARichText: async () => (await import('fsxa-pattern-library')).FSXARichText,
    BaseButton: () => import('../base/BaseButton.vue'),
  },
})
export default class CarouselSlideContent extends Vue {
  @Prop({ default: () => [] }) links! : ILink[]

  @Prop({ default: '' }) text! : string

  @Prop({ required: true }) image! : IImage

  @Prop({ required: true }) variant! : 'stage-large' | 'stage-medium' | 'carousel'

  @Prop() checkmarks ?: RichTextElement[][]

  @Prop() video ?: IVideo

  @Prop() cookieAccepted! : boolean

  private buttonVariant : EButtonVariant = EButtonVariant.Default

  created () {
    this.buttonVariant = this.variant === 'carousel' ? EButtonVariant.Outline : EButtonVariant.Default
  }

  private extractCheckMarkContent (richTextElement : RichTextElement[]) : RichTextElement[] {
    // root block element contains styling for headline which we don't want
    return richTextElement.flatMap((element) => ((Array.isArray(element.content) && element.type === 'block') ? element.content : element))
  }

  private get textClasses () : string {
    switch (this.variant) {
      case 'stage-large': return 'text-base md:text-lg'
      case 'stage-medium': return 'text-lg'
      case 'carousel': return 'text-base'
      default: return ''
    }
  }

  private get videoLabel () : string {
    return globalLabelAsString('show_video_label')
  }

  @Emit('open-lightbox')
  private openLightbox () : void {}
}
